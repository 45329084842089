import Navbar from '../layout/Navbar';
import Produse from '../section/produse';
import Contact from '../section/contact';
import Footer from '../section/footer';
import Preloader from "../layout/Preloader";
import ScrollToTopBtn from '../layout/ScrollToTop';
import { GlobalStyles } from './style/lightnew';
import {useState} from "react";



function HomePage() {
  return (
    <>
      <GlobalStyles />
      <Preloader />
      <div className="home">
        <header id="header-wrap">
          <Navbar />
        </header>

        {/* HOME*/}
        <section id="hero-area" >
          <Produse />
        </section>

        {/* CONTACT */}
        <section id="contact" className="py-0">
          <Contact />
          <Footer />
        </section>
        {/* <div className="spacer-single"></div> */}
      </div>
      <ScrollToTopBtn />
    </>
  );
}

export default HomePage;
