import React, {useContext, useEffect, useState} from 'react';
import { createGlobalStyle } from 'styled-components';
import axios from 'axios'
import {getmainimage, translateField} from '../helpers'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom";

import AOS from 'aos';
import {LanguageContext} from "../LanguageContext";

AOS.init();

const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;
const baseURL = 'https://app.operativcnc.ro/api/products'
const domain = window.location.hostname;

const Produse = function () {
    const [products, setProducts] = useState([]);
    const { currentLanguage } = useContext(LanguageContext);

    useEffect(() => {
        axios.get(`${baseURL}?domain=${domain}`).then((response) => {

            setProducts(response.data);
        });

    }, []);

    if (!products) return null;

    return (
        <>
            <div className="container class-margin">
                <GlobalStyles />
                <div className='loop-products'>
                    {products.map(product => {
                        return <Link key={product.id} to={`/electroeroziune/${product.id}`}>
                            <div key={product.id}>
                                <div className="image-element-class de_modal contents-align-center"
                                    data-aos="fade-up"
                                    data-aos-once="true"
                                >
                                    <div className="card-image-1">
                                        <img src={getmainimage(product.images)} alt="Electroeroziune" />
                                    </div>
                                    <div style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                                        <h3 style={{ fontSize: 17, width: '95%', }}>{translateField(product,'name',currentLanguage)}</h3>
                                    </div>
                                    <div className="space-border2" />
                                </div>
                            </div></Link>
                    })}
                </div>
                <div className="spacer-single"></div>
            </div>
        </>
    );
}

export default Produse;