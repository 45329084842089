import React from 'react';

const Footer = () => {
    return (
        <>
            <footer className="container">
                <div className="container">
                    <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        <p>&copy; Copyright 2022 - Operativ CNC S.R.L</p>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;