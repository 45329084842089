import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-scroll";
import axios from 'axios'
import { LanguageContext } from "../LanguageContext";

const baseURL = 'https://app.operativcnc.ro/api/website-details'
const domain = window.location.hostname;

const Navbar = function () {
  const [showmenu, btn_icon] = useState(false);
  const [webDetails, setWebDetails] = useState([null]);

  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);


  useEffect(() => {
    axios.get(`${baseURL}?domain=${domain}`).then((response) => {
      setWebDetails(response.data.details);
    }).catch(error => console.log('error', error));

  }, []);

  const handleLanguageChange = languageCode => {
    setCurrentLanguage(languageCode);
    localStorage.setItem('operativ_cnc_lang', languageCode)
    setShowLanguageDropdown(false);
  };


  const toggleLanguageDropdown = () => {
    setShowLanguageDropdown(!showLanguageDropdown);
  };


  useEffect(() => {
    const closeDropdown = (event) => {
      if (!event.target.closest('.dropdown')) {
        setShowLanguageDropdown(false);
      }
    };

    window.addEventListener('click', closeDropdown);
    return () => window.removeEventListener('click', closeDropdown);
  }, []);

  useEffect(() => {
    const header = document.getElementById("header-wrap");
    const flytext = document.getElementById("fly");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        totop.classList.add("show");
        flytext.classList.add("hide");
      } else {
        header.classList.remove("sticky");
        flytext.classList.remove("hide");
        totop.classList.remove("show");
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  const translations = {
    // en: { home: "Home", contact: "Contact", address: "Address", phone: "Phone Number" },
    it: { home: "Casa", contact: "Contatto", address: "Indirizzo", phone: "Numero di Telefono" },
    ro: { home: "Acasă", contact: "Contact", address: "Adresă sediu", phone: "Număr de Telefon" },
    // de: { home: "Das Zuhause", contact: "Kontakt", address: "Anschrift der Hauptniederlassung", phone: "Telefonnummer" },

  };

  const languages = [
    { code: 'ro', name: 'Romanian', flag: 'ro' },
    { code: 'it', name: 'Italian', flag: 'it' }
  ];


  return (
    <nav className="navbar transition">
      <div className="container">
        <Link className="navbar-brand" activeClass="active" spy to="hero-area">
          <img src={webDetails.logo} className="logo-mobile" alt="logo" />
        </Link>
        {/* Desktop menu START Here */}
        <div className="dekstopmenu">
          <div className="flexing_langages">
            {/* Language Selector Dropdown */}
            <div className="dropdown">
              <button className="btn btn-secondary" type="button" onClick={toggleLanguageDropdown}>
                <span className={`fi fi-${languages.find(l => l.code === (currentLanguage ?? 'ro')).flag}`} style={{ fontSize: '24px', marginRight: '10px' }}></span>
                <span style={{color: 'black'}}>{languages.find(l => l.code === (currentLanguage ?? 'ro')).name}</span>
                <i className="fa fa-caret-down" aria-hidden="true" style={{ marginLeft: '5px',color: 'black' }}></i>
              </button>
              {showLanguageDropdown && (
                <div className="dropdown-menu" style={{ display: 'block' }}> {/* Inline style to show dropdown */}
                  {languages.map(language => (
                    <button key={language.code} className="dropdown-item" onClick={() => handleLanguageChange(language.code)}>
                      <span className={`fi fi-${language.flag}`} style={{ marginRight: '10px' }}></span>
                      {language.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <div>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link transition" activeClass="active" spy to="hero-area">
                    {translations[currentLanguage].home}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link transition" activeClass="active" spy to="contact">
                    <p>{translations[currentLanguage].contact}</p>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Desktop menu END Here */}


        {/* Mobile menu START here */}
        {showmenu &&
          <div className={`mobilemenu ${showmenu ? 'show' : ''}`} >
            <ul className="navbar-nav mr-auto w-100 justify-content-end clearfix">
              <div className="dropdown new_falg_center">
                <button className="btn btn-secondary" type="button" onClick={toggleLanguageDropdown}>
                  <span className={`fi fi-${languages.find(l => l.code === (currentLanguage ?? 'ro')).flag}`} style={{ fontSize: '24px' }}></span>
                  &nbsp;<span style={{color: 'black'}}>{languages.find(l => l.code === (currentLanguage ?? 'ro')).name}</span>
                  <i className="fa fa-caret-down" aria-hidden="true" style={{ marginLeft: '5px',color: 'black' }}></i>
                </button>
                {showLanguageDropdown && (
                  <div className="dropdown-menu" style={{ display: 'block' }}> {/* Inline style to show dropdown */}
                    {languages.map(language => (
                      <button key={language.code} className="dropdown-item" onClick={() => handleLanguageChange(language.code)}>
                        <span className={`fi fi-${language.flag}`} style={{ marginRight: '10px' }}></span>
                        {language.name}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <li className="nav-item">
                <Link className="nav-link" activeClass="active" smooth spy to="hero-area">
                  <p>{translations[currentLanguage].home}</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link transition" smooth activeClass="active" spy to="contact">
                  <p>{translations[currentLanguage].contact}</p>
                </Link>
              </li>
            </ul>
          </div>
        }
        <button className="burgermenu" type="button" onClick={() => btn_icon(!showmenu)}>
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
        {/* mobile menu END here */}
      </div>
    </nav>
  )
}

export default Navbar;