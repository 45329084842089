export const getmainimage = images => {
    if (!images || !images.length) {
        return null;
    }
    let image = images.find(image => image.main);
    if (image) {
        return image.image;
    }
    return images[0].image
}


export const translateField = (product, field, currentLanguage) => {
    const fallback = product[field];
    if (!product.translations) {
        return fallback ?? '-'
    }
    const translations = product.translations;
    if (!translations[field] || !translations[field][currentLanguage]) {
        return fallback ?? '-'
    }
    return translations[field][currentLanguage];
}


export const initialLanguage = () => {
    const local_storage_lang = localStorage.getItem('operativ_cnc_lang');
    const valid_languages = ['it', 'ro'];// ['en', 'it', 'ro', 'de'];
    if (valid_languages.includes(local_storage_lang)) {
        return local_storage_lang;
    }
    let navigator_language = navigator.language;
    if (navigator_language) {
        navigator_language = navigator_language.split('-');
        if (navigator_language[0]) {
            navigator_language = navigator_language[0]
        }
    }
    if (typeof navigator_language === 'string' && valid_languages.includes(navigator_language)) {
        return navigator_language;
    }
    return 'ro'
}
