import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import AOS from 'aos';
import {LanguageContext} from "../LanguageContext";

AOS.init();

const baseURL = 'https://app.operativcnc.ro/api/website-details';

const Contact = () => {
    const {currentLanguage} = useContext(LanguageContext);
    const [webDetails, setWebDetails] = useState(null);

    useEffect(() => {
        axios.get(baseURL).then((response) => {
            setWebDetails(response.data.details);
        }).catch(error => console.log('error', error));
    }, []);

    const translations = {
        it: {address: "Indirizzo", phone: "Numero di Telefono", email: "Email"},
        ro: {address: "Adresă", phone: "Număr de Telefon", email: "Email"},
    };

    // Use the translation for labels, not for actual data
    const translate = (key) => translations[currentLanguage][key] || key;

    if (!webDetails) return <p>Loading...</p>;

    return (
        <div className="section bg-white py-0">
            <div className="py-5 paddingClass">
                <div className="container">
                    <div className="col-lg-8 offset-lg-2"
                         data-aos="fade-up"
                         data-aos-once="true"
                         data-aos-delay="200"
                         data-aos-duration="1000"
                         data-aos-easing="ease"
                    >
                        <div className="col-lg-12 text-center">
                            <div className="col"
                                 data-aos="fade-up"
                                 data-aos-once="true"
                                 data-aos-delay="0"
                                 data-aos-duration="1000"
                                 data-aos-easing="ease"
                            >
                                <div className="wm-1"></div>
                                <h6>{translate('address')}</h6>
                                {webDetails.address}
                            </div>
                            <div className="col"
                                 data-aos="fade-up"
                                 data-aos-once="true"
                                 data-aos-delay="200"
                                 data-aos-duration="1000"
                                 data-aos-easing="ease"
                            >
                                <div className="wm-1"></div>
                                <h6>{translate('phone')}</h6>
                                {webDetails.phone_number}
                            </div>
                        </div>
                        <div className="custom-container-btn">
                            <a className="btn-main mt-3 custom-btn-width text-white"
                               href={`mailto:${webDetails.email}?body=Scrie%20mesajul%20tau%20aici`}>
                                {translate('email')}
                            </a>
                        </div>
                        <div className="spacer-single"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;