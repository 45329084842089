import { BiChevronLeft } from "react-icons/bi";
import { BiChevronRight } from "react-icons/bi";

export const settings = {
  infinite: true,
  speed: 500,
  centerMode: true,
  centerPadding: 0,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 1,
  adaptiveHeight: 500,
  arrows: true,
  nextArrow: <BiChevronRight color="#137DC5" />,
  prevArrow: <BiChevronLeft color="#137DC5" />,
  pauseOnHover: true,
  dots: true,
  responsive: [
    {
      breakpoint: 1900,
      settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      }
    },
    {
      breakpoint: 1600,
      settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true
      }
    },
    {
      breakpoint: 1024,
      settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true
      }
    },
    {
      breakpoint: 700,
      settings: {
        arrows: true,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 500,
      settings: {
      arrows: false,
      slidesToShow: 1,
      }
    },
    {
      breakpoint: 400,
      settings: {
      arrows: false,
      slidesToShow: 1,
      
      }
    }
  ]
};

