import React, {useState} from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./pages/homePage";
import DetaliiProdus from "./pages/detaliiProdus"
import { LanguageContext } from './LanguageContext';
import {initialLanguage} from "./helpers";


export default function App() {
	const [currentLanguage, setCurrentLanguage] = useState(initialLanguage());
  return (
  	<div>
		<LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage }}>

		<BrowserRouter>
	    <Routes>
		    <Route path = "/" element={ <Homepage/>}/>
		    <Route path = "/electroeroziune/:id" element={<DetaliiProdus/>}/>
		</Routes>
	  </BrowserRouter>
		</LanguageContext.Provider>
    </div>
  );
}

