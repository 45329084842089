import React, {useContext, useEffect, useState,} from 'react';
import { createGlobalStyle } from 'styled-components';
import axios from 'axios'
import Preloader from "../layout/Preloader";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { settings } from '../section/settings';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import { BiChevronLeft } from "react-icons/bi";
import AOS from 'aos';
import {LanguageContext} from "../LanguageContext";
import {translateField} from "../helpers";

AOS.init();

const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

const baseURL = 'https://app.operativcnc.ro/api/products'
const baseURL2 = 'https://app.operativcnc.ro/api/website-details'
const domain = window.location.hostname;


const DetaliiProdus = function () {
    const { id } = useParams();
    const [activeProduct, setActiveProduct] = useState(null)
    const { currentLanguage } = useContext(LanguageContext);

    useEffect(() => {
        axios.get(`${baseURL}/${id}?domain=${domain}`).then((response) => {
            setActiveProduct(response.data.product);
        }).catch(error => console.log('error', error));

    }, [id]);

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const [webDetails, setWebDetails] = useState([null]);
    useEffect(() => {

        // Fetching website details
        axios.get(`${baseURL2}?domain=${domain}`).then((response) => {
            setWebDetails(response.data.details);
        }).catch(error => console.log('error', error));
    }, []);


    if (!activeProduct) return null;
    // if (!activeProduct || !webDetails) return null;
    return (
        <>
            <div className="container">
                <GlobalStyles />
                <Preloader />
                <div className="LightboxGal ">
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="col-md-8 col-sm-12">
                                <div className="slider-wrapper">
                                    <Link className='arrow-wrapper-link' to={`/`}>
                                        <BiChevronLeft className='arrow' style={{ width: 50, height: 50, color: 'rgb(19, 125, 197)' }} />
                                    </Link>
                                    <Slider style={{ backgroundColor: "#171A1D" }} {...settings}>
                                        {activeProduct.images
                                            .filter(image => !image.main)
                                            .map((image, index) => (
                                                <div
                                                    onClick={() => {
                                                        setIsOpen(true);
                                                        setPhotoIndex(index);
                                                    }}
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                    key={image.id}
                                                >
                                                    <img
                                                        width="100%"
                                                        height="500"
                                                        src={image.image}
                                                        alt="Electroeroziune cu fir Sodick"
                                                        className="image-props"
                                                    />
                                                </div>
                                            ))}
                                        {isOpen && (
                                            <Lightbox
                                                mainSrc={activeProduct.images[photoIndex].image}
                                                nextSrc={
                                                    activeProduct.images[(photoIndex + 1)]?  activeProduct.images[(photoIndex + 1)].image:null
                                                }
                                                prevSrc={
                                                    activeProduct.images[photoIndex -1] ?activeProduct.images[photoIndex -1].image:null }
                                                onCloseRequest={() => {setIsOpen(false);setPhotoIndex(0)}}
                                                onMovePrevRequest={photoIndex > 0 ? () => setPhotoIndex(photoIndex - 1)  : null}
                                                onMoveNextRequest={photoIndex < activeProduct.images.length - 1 ? () => setPhotoIndex((photoIndex + 1) ) : null}
                                            />
                                        )}
                                    </Slider>
                                </div>
                                <div className="spacer-single"></div>
                                <div className='margin-after-photo'>
                                    <div style={{ marginTop: "4%" }}>
                                        <div className="post-info">
                                            <p style={{ color: 'black' }}>
                                                {activeProduct && activeProduct.published_at}
                                            </p>
                                        </div>
                                        <h3 style={{ marginBottom: '4%', color: '#333333' }} id="titlu">
                                            {activeProduct && translateField(activeProduct,'name',currentLanguage)}
                                        </h3>
                                        <div style={{ marginBottom: '5%' }} className="d-field ">
                                            <span style={{ fontSize: 30, fontWeight: 'bold', color: '#333333' }}
                                                className='pret-color' id="pret">
                                                {activeProduct && activeProduct.price}&nbsp;lei
                                            </span>
                                        </div>
                                        <h6 style={{color:'#000'}}>Model:&nbsp;<i>{translateField(activeProduct,'model',currentLanguage)}</i></h6>
                                        <hr/>
                                        <h6 style={{color:'#000'}}>Tip de procesare:&nbsp;<i>{translateField(activeProduct,'processing_type',currentLanguage)}</i></h6>
                                        <hr/>
                                        <h6 style={{color:'#000'}}>Tip de înfiletare:&nbsp;<i>{translateField(activeProduct,'threading_type',currentLanguage)}</i></h6>
                                        <hr/>

                                        <h6 style={{color:'#000'}}>An fabricație:&nbsp;<i>{activeProduct.fabrication_year??'-'}</i></h6>

                                        <div className="post-text">
                                            <div dangerouslySetInnerHTML={{ __html: activeProduct && translateField(activeProduct,'description',currentLanguage) }} />
                                        </div>
                                    </div>
                                    <div className='button-container-mobile'>
                                    {webDetails && (
                                        <div style={{
                                            width: '100%',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            paddingBottom: '20%',
                                        }}>

                                            <button className="btn-main mt-3" style={{
                                                marginRight: 20,
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <a className='links-button-two' href={`tel:${webDetails.phone_number}`}>
                                                    TELEFON
                                                </a>
                                            </button>
                                            <button className="btn-main mt-3" style={{ marginRight: 20 }}>
                                            <a href={`mailto:${webDetails.email}?body="Scrie mesajul tau aici"`}>
                                                    EMAIL
                                                </a>
                                            </button>
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DetaliiProdus;
