import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
	* {
	  --body-font: "Roboto", Helvetica, Arial, sans-serif;
	  --title-font: "Roboto", Helvetica, Arial, sans-serif;
	}
	body, section {
        background-color: #fff;
		margin-right: auto;
		margin-left:auto;
		overflow-x: hidden
    }
	.class-margin{
		margin-top: 2.2em;
	}
    .v-center{
    	min-height: auto;
    }
    .color {
	  color: #137DC5;
	}
	.space-border{
		background: #137DC5;
	}
	.btn-main{
		background: #137DC5;
		border-radius: 8px;
	}
	#mainpreloader{
	  	.preloader{
	  		background: #fff;
		}
	}
	.custom-btn-width{
		width: 30%;
	}
	.custom-container-btn{
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 0 !important;
	}
   
   .paddingClass{
		padding-left: 1em;
		padding-right: 1em;
   }
    .list_location li{
    	color: #202020;
    	span{
    		color:#202020;
    	}
    }
    .float-text span{
    	color: #222;
    }
    .navbar-brand .imginit{
    	display: none !important;
    }
    ${'' /* .navbar-brand .imgsaly{
    	display: block;
    } */}
    #header-wrap nav.navbar .navbar-nav .nav-item a.active{
    	color: #137DC5 !important;
    }
    #header-wrap nav.navbar .navbar-nav .nav-item a{
    	color: #222;
    }
    #header-wrap.sticky nav.navbar{
    	background: #ffffff;
    }
    h1, h2, h4, h3, h5, h6{
    	font-weight: 600;
    }
    h1, h2, h4, h3, h5, h6, .h1_big, .h1, .h2, .h3, .h4, .h5, .h6, footer.footer-light h5, .burgermenu, blockquote.q-big, 
    .d_timeline-title, .d_timeline-text .d_title, .d-skills-bar .d-bar .d-info{
    	color: #202020;
    }
    h6.color {
	  font-size: 12px;
	  letter-spacing: 5px;
	  font-weight: 400;
	  text-transform: uppercase;
	  color: #202020;
	}
    .h1_big{
    	font-size: 50px;
    	text-transform: unset;
    }
    .mouse {
    	border: 2px solid rgba(0, 0, 0, 0.9);
    	.scroll{
	    	background: #202020;
	    }
    }
    .progress-bar{
    	background: #f0f0f0 !important;
    }
    .main-bar{
    	padding: 8px;
    }
    .main-bar::before {
		position: absolute;
		content: "";
		height: 0;
		right: 0;
		bottom: -5px;
		width: 0;
		border: 7px solid transparent;
		border-top-color: transparent;
		border-right-width: 7px;
		border-bottom-width: 7px;
		border-bottom-width: 0px;
		border-right-width: 0px;
		border-top-style: #202020;
		border-top-color: #202020;
	}

	.itm2m{
		alignItems: center;
		justifyContent: center;
		width: 60% !important;
	}

	.slick-slide img{
		margin: auto;
        width: 100%;
    object-fit: contain;
	}

	${'' /* .slick-slide img {
    margin: auto;
    width: 100%;
    object-fit: contain;
} */}

	${'' /* .modal-margin{
		padding: 1em;
	} */}

	.newbtn{
		backgroundColor: white !important;
		color: black !important;
	}
	.adtoncls{
		justifyContent: center !important;
		alignItems: center !important;
	}
    .d-quote-by, .de_3d-box {
    	border-left: solid 1px rgba(0, 0, 0, 0.2);
    }
    .de_modal .card-image-1 h3{
    	color: #fff;
    }
    .CircularProgressbar .CircularProgressbar-text{
    	fill: #202020 !important;
    }
    .CircularProgressbar-trail{
    	stroke: rgba(0, 0, 0, .1) !important;
    }
    .d_timeline-title {
	  color: #ffffff;
	  font-weight: 400;
	  font-size: 14px;
	  margin: 0;
	  background: linear-gradient(90deg, rgba(255,255,255,.1) 0%, rgba(255,255,255,0) 100%);
	  &:before {
	    color: #fff;
	    position: relative;
	    left: -22px;
	    display: inline-block;
	    width: 40px;
	    height: 40px;
	    line-height: 40px;
	    text-align: center;
	    background-color: #137DC5;
	    counter-increment: list;
	    content: counter(list)
	  }
	}
	.d_timeline-text .d_company{
		color: #137DC5;	
	}
    .d_timeline-title{
    	color: #202020;
    	background: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    }
    .d_timeline-item {
	  list-style: none;
	  border-left: 1px solid rgba(0, 0, 0, .2);
	  &:last-child{
	    border-left: 1px solid rgba(0, 0, 0, 0);
	  }
	}
	.de_count.text-center{
		.small{
			font-size: 12px;
			text-transform: uppercase;
			color: #202020;
		}
	}
	.post-image{
		border-radius: 8px;
		img{filter: grayscale(0%);}
	}
.contents-align-center{
	justify-content: center;
	align-items: center;
}

     ${'' /* added by me */}
    .loop-products{
	   display: grid;
	   grid-template-columns: 25em 25em 25em;
	   gap: 1em;
	   align-items: flex-start;
	   justify-content:  center;
	   max-width: 1240px;
		
    }
	${'' /* slick cusotom !important */}

		.slider-wrapper {
		width: 100%;
		margin: auto;
		max-height: 500px;
		min-height: 500px;
		background-color: #1A1A1A !important;
		}
		.slick-slide {
		text-align: center;
		position: relative;
		}
		.slick-slide:focus {
		outline: none;
		}
		
	.custom-height{
		height: 60%;
	}
	.slick-dots{
		position: relative;
		bottom: 0;
		margin-top: 10px;
	}
	.slick-dots li button::before{
		color: rgba(0, 0, 0, 0.3) !important;
	}
	.slick-dots li.slick-active button::before{
		color: #137DC5 !important;
	}
	#scroll-to-top div{
		background: none;
		border: solid 1px rgba(0, 0, 0, 0.2);
		&:hover{
			background: none;
		}
		i{
			color: #202020;
		}
	}
	.de_modal .card-image-1{
		border-radius: 8px;
	}
	.LightboxGal{
		background: #fff;
	}
	#blog-comment ol li .comment-info .c_reply{
		border-left-color: rgba(0, 0, 0, 0.2);
	}
	#blog-comment ol li{
		border-bottom-color: rgba(0, 0, 0, 0.1);
	}
	.form-border input[type="text"], .form-border input[type="text"]:focus{
		color: #202020;
		border: solid 1px rgba(0, 0, 0, 0.2);
	}
	#contact_form textarea, #contact_form textarea:focus{
		color: #202020;
		border: solid 1px rgba(0, 0, 0, 0.2);
	}
	#btnsubmit{
		margin-top: 20px;
	}
	.btn:hover, .btn:focus{
		color: #fff;
		background: rgba(var(--primary-color-rgb), 1);
	}
	.LightboxGal .closeGal .button-close{
		border: 1px solid rgba(0, 0, 0, 0.5);
		&:before{
			border-left: 8px solid #202020;
			border-right: 8px solid #202020;
		}
		&:after{
			border-left: 8px solid #202020;
			border-right: 8px solid #202020;
		}
	}
	.de_project-details .d-field{
		border-bottom: solid 1px rgba(0, 0, 0, 0.1);
		i {
		  color: #202020;
		}
	}
	${'' /* .LightboxGal .mainLightbox blockquote{
		color: #606060;
	}
	.LightboxGal .mainLightbox blockquote span::before{
		background: #606060;
	} */}
	.de_project-info p{
		color: #909090;
	}
	.de_project-details{
		color: #202020;
	}
	footer{
		border-top: solid 1px rgba(0, 0, 0, .1);
		color: rgba(0, 0, 0, .8) !important;
		background: rgba(255, 255, 255, 0.1);
		a{
			color: rgba(0, 0, 0, .8) !important;
			i{
				color: rgba(0, 0, 0, .8) !important;
			}
		}
	}
	.mobilemenu{
		background: #fff;
	}
	.mainreveal{
		background: #fff;
	}
	#de-menu-reveal .lt, #de-menu-reveal .lm, #de-menu-reveal .lb{
		background: #202020;
	}
	.de_modal .card-image-1 img{
		filter: grayscale(0%);
		max-width: 100%;
		background-color: #ffffff;
	}
  .logo-mobile{
	width: min(40vw, 80px);
	height: min(10vw, 70px)
   
  }
  .btn-secondary{
		--bs-btn-bg: transparent!important;
		border: none!important;
		margin-bottom: 16px;
	}
	@media only screen and (max-width: 1200px) {
		.loop-products{
		display: grid;
		grid-template-columns: 30em 30em;
		gap: 1em;
		align-items: center;
		justify-content: space-around;
       }
	   .btn-secondary{
		--bs-btn-bg: transparent!important;
		border: none!important;
		margin-bottom: 16px;

	}
    }

	@media only screen and (max-width: 966px){
		.v-center{
			margin-top: 120px;
		}
		.loop-products{
		display: grid;
		grid-template-columns:  40em;
		gap: 1em;
		align-items: center;
		justify-content: space-around;
       }
	   .new_falg_center{
		position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* align-content: center; */
    align-items: center;
	}

	.btn-secondary{
		--bs-btn-bg: transparent!important;
		border: none!important;
		margin-bottom: 16px;
	}
	}

	
	

	@media only screen and (max-width: 768px) {
		.loop-products{
		display: flex;
		flex-direction: column;
		gap: 0em;
		align-items: center;
		width: 100%;
		${'' /* justify-content: space-around; */}
	    
    }
	.new_falg_center{
		position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* align-content: center; */
    align-items: center;
	}

	.btn-secondary{
		--bs-btn-bg: transparent!important;
		border: none!important;
		margin-bottom: 16px;
	}
	.slider-wrapper {
		width: 100%;
		background-color: red;
		margin: 0;
		max-height: 500px;
		min-height: 500px; 
  max-width: 48em; 
		 background-color: #1A1A1A; 
		}
	 .slick-slide img {
         margin: auto;
         width: 100%;
         height: 50%;
	     object-fit: contain;
}
	.container{
		padding-left: 0;
		padding-right: 0;
		max-width: 740px;
	}

	
	.logo-mobile{
		height: 3.7em!important;
		width: 7em;
	}

	.paddingClass{
		width: 100%
	}
	}



	@media only screen and (max-width: 540px) {
	.new_falg_center{
		position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* align-content: center; */
    align-items: center;
	}

	.btn-secondary{
		--bs-btn-bg: transparent!important;
		border: none!important;
		margin-bottom: 16px;
	}
	.mobilemenu {
		opacity: 0;
		transform: translateY(-20px);
		transition: opacity 0.3s ease, transform 0.3s ease;
		display: none; /* Initially hidden */
	}

	.mobilemenu.show {
		display: block; /* Shown when menu is active */
		opacity: 1;
		transform: translateY(0);
	}

	.logo-mobile{
		max-width: 75% !important;
		height: min(15vw, 70px) !important;
		width: 6em;
	}
	.modal-margin{
		padding: 1em 0.5em;
	}

	.class-margin{
		margin-top: 4em;
	}

	#hero-area{
		padding: 1.3em;
		padding-top: 0.1em
	}
	#about{
		padding: 1.3em;
	}
	#contact{
		padding: 1.3em;
	}
	#gallery{
		padding: 1em;
	}
	.dynamic-text-home{
		padding-left: .1em;
	},
	.de_project-details{
		max-width: 93%;
		width: 90%
		background: red;
	}
.v-center {
    margin-top: -22%;
}
.LightboxGal .closeGal .button-close{
	display: block;
    position: fixed;
	z-index: 9999999999;
	top: 16px;
    right: 20px;
    width: 48px;
    height: 48px;
    background: none;
    color: #ffffff;
    outline: none;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	}

	.LightboxGal .closeGal .button-close:before {
    border-left: 8px solid #ffffff;
    border-right: 8px solid #ffffff;
}


.LightboxGal .closeGal .button-close:after {
    border-left: 8px solid #ffffff;
    border-right: 8px solid #ffffff;
}
.LightboxGal{
	overflow-x: hidden;
}



.slider-wrapper {
		width: 100%;
		background-color: red;
		margin: 0;
		max-height: 500px;
		min-height: 500px; 
  max-width: 48em; 
		 background-color: #1A1A1A; 
		}
	 .slick-slide img {
         margin: auto;
         width: 100%;
         height: 50%;
	     object-fit: contain;
}
.image-element-class {

  padding: 0;
  img{
    width: 110%;
    height: 350px;
    object-fit: contain;
	background-color: #1A1A1A;
  }
}
	
`;